import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { Routes as DashboardRoutes } from './dashboard/components/routing';
import { loadUser } from './store/auth';
import store from './store/store';
import './styles/App.css';
import DynamicScrollToTop from './upload/components/layout/DynamicScrollToTop';
import { Routes as UploadRoutes } from './upload/components/routing/Routes';
import Register from './upload/components/views/Auth/Register';
import setAuthToken from './upload/utils/setAuthToken';
import 'bootstrap/dist/css/bootstrap.min.css';

const token = localStorage.getItem('token');

if (token) {
  setAuthToken(token);
}

export const App: React.FC = () => {
  useEffect(() => {
    if (token) {
      store.dispatch(loadUser());
    }
  }, []);

  return (
    <Provider store={store}>
      <Router basename="/">
        <DynamicScrollToTop />
        <Switch>
          <Route exact path="/" component={Register} />
          <Route path="/dashboard" component={DashboardRoutes} />
          <Route component={UploadRoutes} />
        </Switch>
      </Router>
    </Provider>
  );
};
