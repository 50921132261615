import React, { useEffect, useRef, useState } from 'react';

import Helpers from '../../../../utils/Helpers';

import styles from '../LineChart.module.scss';

const labelMappings = {
  all: 'Total Views',
  unique: 'Unique Views',
  clicks: 'Clicks',
  users: 'Users',
};

const formatLabel = (key) => labelMappings[key];

const CustomLineChartNumberToolTip = ({
  point,
  height,
  yDataKey,
  xDataKey,
  yDataKey2 = null,
  toolTipEndCharacter,
}) => {
  const tooltipRef = useRef(null);
  const [formattedValue1, setFormattedValue1] = useState('');
  const [formattedValue2, setFormattedValue2] = useState('');

  const updateTooltip = () => {
    if (point) {
      const x = Math.round(point.x);
      const y = Math.round(point.y);
      const tooltipWidth = tooltipRef.current.offsetWidth;
      const tooltipHeight = tooltipRef.current.offsetHeight;

      tooltipRef.current.style.opacity = '1';
      tooltipRef.current.style.transform = `translate(${x - tooltipWidth / 2}px, ${
        y - (height + 20 + tooltipHeight)
      }px)`;
      setFormattedValue1(Helpers.formatToolTipDate(point.payload[xDataKey]));
      const yValue = point.payload[yDataKey];
      setFormattedValue2(`${Helpers.formatNumber(yValue)} ${toolTipEndCharacter}`);

      if (yDataKey2) {
        setFormattedValue1(
          `${formatLabel(yDataKey)}: ${Helpers.formatNumber(point.payload[yDataKey])}`,
        );
        setFormattedValue2(
          `${formatLabel(yDataKey2)}: ${Helpers.formatNumber(point.payload[yDataKey2])}`,
        );
      }
    } else {
      tooltipRef.current.style.opacity = '0';
    }
  };

  useEffect(() => {
    updateTooltip();
  }, [point, yDataKey, yDataKey2]);

  return (
    <div className={styles.customTooltipWrapper}>
      <div ref={tooltipRef} className={styles.customTooltip}>
        <p>{formattedValue1}</p>
        {formattedValue2 && <p>{formattedValue2}</p>}
      </div>
    </div>
  );
};

export default CustomLineChartNumberToolTip;
