// TYPES
const CHANGE_LANGUAGE = 'global/changeLanguage';
const CHANGE_THEME = 'global/changeTheme';

// INITIAL STATE
const initialState = {
  language: { locale: 'en', displayName: 'Eng', direction: 'ltr' },
  theme: 'dark',
};

// REDUCER
export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: payload,
      };
    case CHANGE_THEME:
      return {
        ...state,
        theme: payload,
      };
    default:
      return state;
  }
}

// ACTIONS
export const changeLanguage = (language) => async (dispatch) => {
  dispatch({
    type: CHANGE_LANGUAGE,
    payload: language,
  });
};

export const changeTheme = (theme) => async (dispatch) => {
  dispatch({
    type: CHANGE_THEME,
    payload: theme,
  });
};
