import React, { useEffect, useMemo, useState } from 'react';

import Loader from '../../loader/Loader';
import classNames from 'classnames';

import styles from './TabsWidget.module.scss';

interface ITabWidgetData {
  label: string;
  key: string;
  value?: string | number;
  icon?: string;
  chart?: React.ReactNode;
  table?: React.ReactNode;
}

interface ITabsWidgetProps {
  className?: string;
  data?: ITabWidgetData[];
  loading?: boolean | Record<string, boolean>;
}

const TabsWidget: React.FC<ITabsWidgetProps> = ({ className, data, loading = false }) => {
  const [activeTab, setActiveTab] = useState<string>();

  const activeTabData = useMemo(
    () => data?.find((item) => item.label === activeTab),
    [activeTab, data],
  );

  useEffect(() => {
    if (!data) return;

    setActiveTab(data[0]?.label);
  }, [data]);

  if (!data) {
    return;
  }

  const tabsClassMap: Record<string | number, string> = {
    2: styles.reachTabs,
    5: styles.earningTabs,
    default: styles.tabs,
  };

  const tabsClassName = tabsClassMap[data?.length] || tabsClassMap.default;

  const isLoading = (key: string) => (typeof loading === 'boolean' ? loading : loading[key]);

  const renderTabWithChart = (data: ITabWidgetData, index: number) => (
    <div
      key={index}
      className={classNames(styles.tabWithChart, activeTab === data.label ? styles.activeTab : '')}
      onClick={() => setActiveTab(data.label)}
    >
      <p>{data.label}</p>
      {!isLoading(data.key) ? <h1>{data.value}</h1> : <Loader />}
    </div>
  );

  const renderTabWithoutChart = (data: ITabWidgetData, index: number) => (
    <div key={index} className={styles.tabWithoutChart}>
      <div className={styles.iconWrapper}>{data.icon}</div>
      <p>{data.label}</p>
      {!isLoading(data.key) ? <h1>{data.value}</h1> : <Loader />}
    </div>
  );

  const tabsWidgetClassName = classNames(className, styles.container);

  return (
    <div className={tabsWidgetClassName}>
      <div className={tabsClassName}>
        {data.map((datum, index) =>
          !datum.icon ? renderTabWithChart(datum, index) : renderTabWithoutChart(datum, index),
        )}
      </div>
      {activeTabData?.chart && !isLoading(activeTabData.key) && (
        <div
          className={classNames(
            styles.chartContainer,
            activeTabData?.table ? styles.earningsChart : '',
          )}
          key={activeTabData?.label}
        >
          {activeTabData?.chart}
        </div>
      )}
      {activeTabData?.table && <div className={styles.table}>{activeTabData?.table}</div>}
    </div>
  );
};

export default TabsWidget;
