import React from 'react';

import WidgetTitle from '../widgetTitle/WidgetTitle';
import classNames from 'classnames';

import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './InformationWidget.module.scss';

const InformationWidget = ({ name, description, data, chart, loading = false }) => {
  return (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <WidgetTitle title={name} description={description} />
          <div className={styles.info}>
            {data?.map((datum, i) => {
              return (
                <ul key={i}>
                  <li>
                    <div style={{ color: datum.color }} className={styles.dot}></div>
                    <span>{datum.name}</span>
                  </li>
                  <p>{datum.value}%</p>
                </ul>
              );
            })}
          </div>
          <div className={styles.chartWrapper}>{chart}</div>
        </>
      ) : null}
    </div>
  );
};

export default InformationWidget;
