import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logout } from '../../../../store/auth';
import { resetUploadState } from '../../../../store/upload';
import axios from 'axios';
import classNames from 'classnames';
import * as moment from 'moment';

import styles from './Uploads.module.scss';

const Uploads = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [uploads, setUploads] = useState([]);

  useEffect(() => {
    const getUploads = async () => {
      try {
        const uploadsRes = await axios
          .get(`${process.env.REACT_APP_API_URL}/video/getVideos?count=15&page=1`)
          .then((res) => res.data);
        setUploads(uploadsRes.data);
      } catch (error) {
        console.error(error);

        const isUnauthorized = error.response.data.errors[0].error === 'Unauthorized';
        if (isUnauthorized) {
          dispatch(resetUploadState());
          dispatch(logout(history));
          return;
        }
      }
    };

    getUploads();
  }, []);

  return (
    <section className={classNames('uploads-section', styles.uploadsSection)}>
      <h1 className={styles.uploadsPageTitle}>Your Uploads</h1>
      <div className={styles.uploadsList}>
        {uploads.map((upload) => (
          <div key={upload.video_id} className={styles.uploadedVideo}>
            <div className={styles.uploadedVideoInfo}>
              <span className={styles.uploadDate}>
                {moment(upload.created_at).format('MMMM DD, YYYY')}
              </span>
              {upload.status.id === 3 && (
                <span className={classNames(styles.statusLabel, styles.rejected)}>REJECTED</span>
              )}
              {upload.status.id === 2 && (
                <span className={classNames(styles.statusLabel, styles.approved)}>APPROVED</span>
              )}
              {upload.status.id === 1 && (
                <span className={classNames(styles.statusLabel, styles.inReview)}>IN REVIEW</span>
              )}
            </div>
            <div className={styles.uploadedVideoParts}>
              <div className={styles.videoBoxOuterDiv}>
                <div className={styles.filePreview}>
                  <span>
                    File name: <strong>{upload.file_name}</strong>
                  </span>
                  <div className={styles.previewImageContainer}>
                    <img className={styles.previewImage} src={upload.video_preview} alt="Preview" />
                  </div>
                </div>
              </div>
              <div className={styles.videoBoxOuterDiv}>
                <div className={styles.filePreview}>
                  <span>
                    File name: <strong>{upload.thumbnail_name}</strong>
                  </span>
                  <div className={styles.previewImageContainer}>
                    <img className={styles.previewImage} src={upload.thumbnail} alt="Thumbnail" />
                  </div>
                </div>
              </div>
              <div className={styles.videoBoxOuterDiv}>
                <div className={styles.filePreview}>
                  <span>
                    File name: <strong>{upload.cover_name}</strong>
                  </span>
                  <div className={styles.previewImageContainer}>
                    <img className={styles.previewImage} src={upload.cover} alt="Cover" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Uploads;
