import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFetch } from '../../../../../hooks';
import { applyQueryParameters } from '../../../../../utils';
import { ReactComponent as CommentsCountIcon } from '../../../../assets/icons/CommentsCountIcon.svg';
import { ReactComponent as LikesCountIcon } from '../../../../assets/icons/LikesCountIcon.svg';
import { ReactComponent as SharesCountIcon } from '../../../../assets/icons/SharesCountIcon.svg';
import { customTickNumberFormatter } from '../../../../components/charts/AreaChart/numberFormatter';
// import { customTickSecondsFormatter } from '../../../../components/charts/AreaChart/secondsFormatter';
import CustomLineChart from '../../../../components/charts/LineChart/LineChart';
import { CustomDot } from '../../../../components/charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../../../components/charts/LineChart/components/CustomLineChartNumberToolTip';
// import CustomLineChartSecondsToolTip from '../../../../components/charts/LineChart/components/CustomLineChartSecondsToolTip';
import PeriodSelectUtils from '../../../../components/periodSelect/PeriodSelectUtils';
import Helpers from '../../../../utils/Helpers';

const REQUEST_CACHE_TTL_IN_SECONDS = 30;

export const geographyColors = ['#074DFF', '#6AD2FF', '#1B2559', '#C6C7F8'];
export const subscriptionStatusColors = ['#A3AED0', '#074DFF'];

const formatXAxis = (tickItem) => {
  const date = new Date(tickItem);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

const transformSubscriptionStatusData = (data) => {
  if (!data) return [];

  return [
    {
      name: 'Free',
      value: Helpers.calculatePercentage(data.freeUsers, data.totalUsers),
      color: subscriptionStatusColors[0],
    },
    {
      name: 'Subscribed',
      value: Helpers.calculatePercentage(data.vipUsers, data.totalUsers),
      color: subscriptionStatusColors[1],
    },
  ];
};

const transformGeographyData = (data) => {
  if (!data) return [];

  const total = data.reduce((sum, entry) => sum + entry.usersNumber, 0);
  const sortedData = data.sort((a, b) => b.usersNumber - a.usersNumber);

  const topCountries = sortedData.slice(0, 3).map((entry, index) => ({
    name: entry.country,
    value: Helpers.calculatePercentage(entry.usersNumber, total),
    color: geographyColors[index] || geographyColors[0],
  }));

  if (data.length > 3) {
    const otherCountries = 100 - topCountries.reduce((sum, entry) => sum + entry.value, 0);
    topCountries.push({
      name: 'Other',
      value: otherCountries,
      color: geographyColors[topCountries.length],
    });
  }

  return topCountries;
};

const transformChartData = (data) => {
  if (!data) {
    return [];
  }

  const groupedChartData = Helpers.groupByDate({
    data: data,
    groupBy: 'year-month',
  });

  const mappedGroupedChartDataByDate = Object.entries(groupedChartData).map(([key, array]) => {
    return { name: key, value: Helpers.calculateSum(array, 'views') };
  });

  return mappedGroupedChartDataByDate;
};

const transformOverviewData = (data) => {
  const initialTabs = [
    {
      label: 'Total views',
      value: '0',
    },
    {
      label: 'Unique views',
      value: '0',
    },
    // {
    //   label: 'Total watch time',
    //   value: '00:00:00',
    // },
  ];
  if (!data) return initialTabs;

  if (data?.totalViews) {
    const totalViewsChartData = transformChartData(data?.totalViewsChart);
    initialTabs[0].value = Helpers.formatNumber(data?.totalViews);
    initialTabs[0].chart = (
      <CustomLineChart
        height={325}
        data={totalViewsChartData}
        CustomToolTip={CustomLineChartNumberToolTip}
        yCustomTickFormatter={customTickNumberFormatter}
        CustomDot={CustomDot}
        xCustomTickFormatter={formatXAxis}
      />
    );
  }
  if (data?.uniqueViews) {
    const uniqueViewsChartData = transformChartData(data?.uniqueViewsChart);
    (initialTabs[1].value = Helpers.formatNumber(data?.uniqueViews)),
      (initialTabs[1].chart = (
        <CustomLineChart
          height={325}
          data={uniqueViewsChartData}
          CustomToolTip={CustomLineChartNumberToolTip}
          yCustomTickFormatter={customTickNumberFormatter}
          CustomDot={CustomDot}
          xCustomTickFormatter={formatXAxis}
        />
      ));
  }
  // if (data?.totalWatchTime) {
  //   const totalWatchTimeChartData = transformChartData(data?.totalWatchTimeChart);
  //   (initialTabs[2].value = Helpers.formatSeconds(data?.totalWatchTime)),
  //     (initialTabs[2].chart = (
  //       <CustomLineChart
  //         height={325}
  //         data={totalWatchTimeChartData}
  //         CustomToolTip={CustomLineChartSecondsToolTip}
  //         yCustomTickFormatter={customTickSecondsFormatter}
  //         CustomDot={CustomDot}
  //         xCustomTickFormatter={formatXAxis}
  //       />
  //     ));
  // }

  return initialTabs;
};

const transformEngagementData = (data) => {
  return [
    {
      label: 'Likes',
      value: Helpers.formatNumber(data?.likes),
      icon: <LikesCountIcon />,
    },
    {
      label: 'Comments',
      value: Helpers.formatNumber(data?.comments),
      icon: <CommentsCountIcon />,
    },
    {
      label: 'Shares',
      value: Helpers.formatNumber(data?.shares),
      icon: <SharesCountIcon />,
    },
  ];
};

export const useSingleVideoData = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [datePeriod, setDatePeriod] = useState(PeriodSelectUtils.thisYear());

  const { id } = useParams();

  const fromDate = datePeriod.fromDate?.toISOString().split('T')[0];
  const toDate = datePeriod.toDate?.toISOString().split('T')[0];

  const isDatePeriodSelected = fromDate && toDate;

  const usersInfoDataUrl = `${process.env.REACT_APP_CREATOR_URL}/myvideos/${id}/users-info`;

  const usersInfo = useFetch(
    isDatePeriodSelected
      ? applyQueryParameters(usersInfoDataUrl, { from: fromDate, to: toDate })
      : usersInfoDataUrl,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const geography = usersInfo.data?.geography;
  const usersTypes = usersInfo.data?.usersTypes;

  const videoInfo = useFetch(`${process.env.REACT_APP_CREATOR_URL}/myvideos/${id}`, {
    cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
  });

  const overviewUrl = `${process.env.REACT_APP_CREATOR_URL}/myvideos/${id}/overview`;

  const { data: overview, isLoading: isOverviewLoading } = useFetch(
    activeTab === 0
      ? isDatePeriodSelected
        ? applyQueryParameters(overviewUrl, { from: fromDate, to: toDate })
        : overviewUrl
      : null,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const { data: engagement, isLoading: isEngagementLoading } = useFetch(
    activeTab === 1 ? `${process.env.REACT_APP_CREATOR_URL}/myvideos/${id}/engagement` : null,
    {
      cacheTtlInSeconds: REQUEST_CACHE_TTL_IN_SECONDS,
    },
  );

  const transformedSubscriptionStatusData = useMemo(
    () => transformSubscriptionStatusData(usersTypes),
    [usersTypes],
  );

  const transformedGeographyData = useMemo(() => transformGeographyData(geography), [geography]);

  const transformedOverviewData = useMemo(() => transformOverviewData(overview), [overview]);

  const transformedEngagementData = useMemo(
    () => transformEngagementData(engagement),
    [engagement],
  );

  return {
    activeTab,
    setActiveTab,
    setDatePeriod,
    videoInfo: videoInfo.data,
    isUsersInfoLoading: usersInfo.isLoading,
    isOverviewLoading,
    isEngagementLoading,
    subscriptionStatus: transformedSubscriptionStatusData,
    geography: transformedGeographyData,
    overview: transformedOverviewData,
    engagement: transformedEngagementData,
  };
};
