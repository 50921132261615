import React from 'react';

import { generateRandomColor } from '../PieChart/colorUtils';
import { Cell, Pie, PieChart } from 'recharts';

const CustomDoughnutChart = ({
  data,
  width,
  height = 400,
  cx = '50%',
  cy = '50%',
  innerRadius = 60,
  outerRadius = 90,
  yDataKey = 'value',
  paddingAngle = 5,
  colors = Array.from({ length: data.length }, () => generateRandomColor()),
}) => {
  return (
    <PieChart width={width} height={height}>
      <Pie
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        paddingAngle={paddingAngle}
        dataKey={yDataKey}
      >
        {data?.map((_, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CustomDoughnutChart;
