import React from 'react';
import { Switch } from 'react-router-dom';

import PrivateRoute from '../../../layout/PrivateRoute';
import Analytics from '../../pages/Analytics/Analytics';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Earnings from '../../pages/Earnings/Earnings';
import MyVideos from '../../pages/myVideos/MyVideos';
import SingleVideo from '../../pages/myVideos/components/singleVideo/SingleVideo';

export const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/dashboard" component={Dashboard} />
    <PrivateRoute exact path="/dashboard/analytics" component={Analytics} />
    <PrivateRoute exact path="/dashboard/myVideos" component={MyVideos} />
    <PrivateRoute path="/dashboard/myVideos/:id" component={SingleVideo} />
    <PrivateRoute exact path="/dashboard/earnings" component={Earnings} />
  </Switch>
);
