import React, { useMemo } from 'react';

// import { ReactComponent as InfoIcon } from '../../../assets/icons/InfoIcon.svg';
import Helpers from '../../../utils/Helpers';
import CustomAreaChart from '../../charts/AreaChart/AreaChart';
import { customTickNumberFormatter } from '../../charts/AreaChart/numberFormatter';
import { CustomDot } from '../../charts/LineChart/components/CustomDot';
import CustomLineChartNumberToolTip from '../../charts/LineChart/components/CustomLineChartNumberToolTip';
import classNames from 'classnames';

import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './EarningsWidget.module.scss';

const formatXAxis = (tickItem) => {
  const date = new Date(tickItem);
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
};

const getSortedChartData = (data) =>
  data
    ? data?.sort((a, b) => {
        const dateA = new Date(a.name);
        const dateB = new Date(b.name);
        const diff = dateA - dateB;

        if (diff > 0) {
          return 1;
        }
        if (diff < 0) {
          return -1;
        }
        return 0;
      })
    : [];

const EarningsWidget = ({ data, loading = false }) => {
  const totalEarnings = useMemo(() => Helpers.calculateSum(data, 'value'), [data]);

  const groupedData = useMemo(() => {
    const groupedDataByDate = Helpers.groupByDate({
      data,
      dateKey: 'name',
    });

    return getSortedChartData(
      Object.entries(groupedDataByDate).map(([key, array]) => ({
        name: key,
        value: Helpers.calculateSum(array, 'value'),
      })),
    );
  }, [data]);

  if (!data) {
    return;
  }

  return (
    <div className={classNames(styles.container, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div className={styles.header}>
            <span>
              <h1>Total Earnings </h1>
              <p>${Helpers.formatNumber(totalEarnings)}</p>
            </span>
            {/*<InfoIcon />*/}
          </div>
          <CustomAreaChart
            data={groupedData}
            height={150}
            yCustomTickFormatter={customTickNumberFormatter}
            xCustomTickFormatter={formatXAxis}
            CustomDot={CustomDot}
            CustomToolTip={CustomLineChartNumberToolTip}
            toolTipEndCharacter="$"
            xFontSize={12}
          />
        </>
      ) : null}
    </div>
  );
};

export default EarningsWidget;
