import alertReducer from './alert';
import authReducer from './auth';
import globalReducer from './global';
import progressAlertReducer from './progressAlert';
import uploadReducer from './upload';
import { combineReducers } from 'redux';

export default combineReducers({
  alert: alertReducer,
  progressAlert: progressAlertReducer,
  global: globalReducer,
  auth: authReducer,
  upload: uploadReducer,
});
