import React from 'react';

import styles from './ProgressBar.module.scss';

const CustomProgressBar = ({ label, percentage, color = '#1b2559' }) => {
  return (
    <div className={styles.container}>
      <h1>{label}</h1>
      <p style={{ color: color }}>{percentage || 0}%</p>
      <div className={styles.progressBarContainer}>
        <div
          style={{ width: `${percentage}%`, background: color }}
          className={styles.progressBar}
        ></div>
      </div>
    </div>
  );
};

export default CustomProgressBar;
