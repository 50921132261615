import React, { useState } from 'react';

import { useFetch } from '../../../hooks';
import { applyQueryParameters } from '../../../utils';
import PeriodSelectUtils, { IDatePeriod } from '../../components/periodSelect/PeriodSelectUtils';
import VideoStatusTag from '../../components/videoStatusTag/VideoStatusTag';
import Helpers from '../../utils/Helpers';

const configurationUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/configuration`;
const videoEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/videos`;
const milestonesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/milestones`;
const audioRoomsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/audio-rooms`;
const videoRoomsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/video-rooms`;
const shopSalesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/shop-sales`;
const raffleDrawsEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/raffle-draws`;
const promoCodesEarningsUrl = `${process.env.REACT_APP_CREATOR_URL}/earnings/promo-codes`;

export interface IEarningsConfiguration {
  audioRoomsEarningEnabled: boolean;
  videoRoomsEarningEnabled: boolean;
  promoCodeEarningEnabled: boolean;
  raffleDrawEarningEnabled: boolean;
  shopRevenueEnabled: boolean;
  videoViewsEarningEnabled: boolean;
}

interface IEarningsMilestones {
  actualClicks: number;
  actualInstalls: number;
  createdAt: string;
  fetchedDataFromBranch: number;
  id: number;
  influencerId: number;
  isDeleted: number;
  milestone1Bonus: number;
  milestone1Clicks: number;
  milestone1Cost: number;
  milestone1Users: string;
  milestone1UsersMax: number;
  milestone1UsersMin: number;
  milestone2Bonus: number;
  milestone2Clicks: number;
  milestone2Cost: number;
  milestone2Users: string;
  milestone2UsersMax: number;
  milestone2UsersMin: number;
  milestone3Bonus: number;
  milestone3Clicks: number;
  milestone3Cost: number;
  milestone3Users: string;
  milestone3UsersMax: number;
  milestone3UsersMin: number;
  month: string;
}

export interface IVideoEarnings {
  chart: { date: string; earning: number }[];
  earnings: {
    commentsCount: number;
    earning: number;
    image: string;
    isPremium: number;
    likesCount: number;
    liveDateTime: string;
    previewTime: number;
    sharesCount: number;
    thumbnail: string;
    title: string;
    totalViews: number;
    videoId: number;
  }[];
}

export interface IAudioRoomEarnings {
  chart: { date: string; earning: number }[];
  earnings: {
    roomName: string;
    date: string;
    gifts: number;
    points: number;
    users: number;
    joinsEarnings: number;
    giftsEarnings: number;
  }[];
}

export interface IVideoRoomEarnings {
  chart: { date: string; earning: number }[];
  earnings: {
    id: number;
    roomName: string;
    date: string;
    gifts: number;
    users: number;
    points: number;
    joinsEarnings: number;
    giftsEarnings: number;
  }[];
}

export interface IShopSaleEarnings {
  chart: { date: string; earning: number }[];
  earnings: {
    id: number;
    name: string;
    date: Date | string;
    unitsSold: number;
    earnings: number;
  }[];
}

export interface IRaffleDrawEarnings {
  chart: { date: string; earning: number }[];
  earnings: {
    id: number;
    name: string;
    startDate: Date | string;
    endDate: Date | string;
    entries: number;
    earnings: number;
  }[];
}

export interface IPromoCodeEarnings {
  chart: { date: Date | string; earning: number }[];
  earnings: {
    id: number;
    date: Date | string;
    subscribers: number;
    earnings: number;
  }[];
}

export const useEarningsData = () => {
  const [datePeriod, setDatePeriod] = useState<IDatePeriod>(PeriodSelectUtils.thisYear());

  const fromDate = datePeriod.fromDate?.toISOString().split('T')[0];
  const toDate = datePeriod.toDate?.toISOString().split('T')[0];

  const isDatePeriodSelected = fromDate && toDate;

  const { data: configuration } = useFetch<IEarningsConfiguration>(configurationUrl);

  const { data: milestonesEarnings } = useFetch<IEarningsMilestones>(
    configuration ? milestonesEarningsUrl : null,
  );

  const videosEarnings = useFetch<IVideoEarnings>(
    configuration?.videoViewsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(videoEarningsUrl, { from: fromDate, to: toDate })
        : videoEarningsUrl
      : null,
  );

  const audioRoomsEarnings = useFetch<IAudioRoomEarnings>(
    configuration?.audioRoomsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(audioRoomsEarningsUrl, { from: fromDate, to: toDate })
        : audioRoomsEarningsUrl
      : null,
  );

  const videoRoomsEarnings = useFetch<IVideoRoomEarnings>(
    configuration?.videoRoomsEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(videoRoomsEarningsUrl, { from: fromDate, to: toDate })
        : videoRoomsEarningsUrl
      : null,
  );

  const shopSalesEarnings = useFetch<IShopSaleEarnings>(
    configuration?.shopRevenueEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(shopSalesEarningsUrl, { from: fromDate, to: toDate })
        : shopSalesEarningsUrl
      : null,
  );

  const raffleDrawsEarnings = useFetch<IRaffleDrawEarnings>(
    configuration?.raffleDrawEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(raffleDrawsEarningsUrl, { from: fromDate, to: toDate })
        : raffleDrawsEarningsUrl
      : null,
  );

  const promoCodesEarnings = useFetch<IPromoCodeEarnings>(
    configuration?.raffleDrawEarningEnabled
      ? isDatePeriodSelected
        ? applyQueryParameters(promoCodesEarningsUrl, { from: fromDate, to: toDate })
        : promoCodesEarningsUrl
      : null,
  );

  const isTotalDataLoading =
    videosEarnings.isLoading ||
    audioRoomsEarnings.isLoading ||
    videoRoomsEarnings.isLoading ||
    shopSalesEarnings.isLoading ||
    raffleDrawsEarnings.isLoading ||
    promoCodesEarnings.isLoading;

  return {
    setDatePeriod,
    isTotalDataLoading,
    milestonesEarnings,
    configuration,
    isVideoEarningsLoading: videosEarnings.isLoading,
    videosEarnings,
    isAudioRoomsEarningsLoading: audioRoomsEarnings.isLoading,
    audioRoomsEarnings,
    isVideoRoomsEarningsLoading: videoRoomsEarnings.isLoading,
    videoRoomsEarnings,
    isShopSalesEarningsLoading: shopSalesEarnings.isLoading,
    shopSalesEarnings,
    isRaffleDrawsEarningsLoading: raffleDrawsEarnings.isLoading,
    raffleDrawsEarnings,
    isPromoCodesEarningsLoading: promoCodesEarnings.isLoading,
    promoCodesEarnings,
  };
};

export const getEarningsDataMap = (
  configuration: IEarningsConfiguration,
  {
    videosEarnings: { data: videoEarnings },
    audioRoomsEarnings: { data: audioRoomsEarnings },
    videoRoomsEarnings: { data: videoRoomsEarnings },
    shopSalesEarnings: { data: shopSalesEarnings },
    raffleDrawsEarnings: { data: raffleDrawsEarnings },
    promoCodesEarnings: { data: promoCodesEarnings },
  }: {
    videosEarnings: { data: IVideoEarnings | null };
    audioRoomsEarnings: { data: IAudioRoomEarnings | null };
    videoRoomsEarnings: { data: IVideoRoomEarnings | null };
    shopSalesEarnings: { data: IShopSaleEarnings | null };
    raffleDrawsEarnings: { data: IRaffleDrawEarnings | null };
    promoCodesEarnings: { data: IPromoCodeEarnings | null };
  },
) => {
  const tabs: {
    key: string;
    label: string;
    data?: {
      date: string | Date;
      earning: number;
    }[];
    tableData:
      | ReturnType<typeof transformVideosEarningsTableData>
      | ReturnType<typeof transformAudioRoomsEarningTableData>
      | ReturnType<typeof transformVideoRoomsEarningTableData>
      | ReturnType<typeof transformShopSalesEarningsTableData>
      | ReturnType<typeof transformRaffleDrawEarningsTableData>
      | ReturnType<typeof transformPromoCodesEarningsTableData>;
    tableTitle: string;
  }[] = [];

  if (!configuration) return;

  if (configuration.videoViewsEarningEnabled) {
    tabs.push({
      key: 'videoEarnings',
      label: 'Video earnings' || 'Video views',
      data: videoEarnings?.chart,
      tableData: transformVideosEarningsTableData(videoEarnings?.earnings),
      tableTitle: 'Video Earnings',
    });
  }

  if (configuration.audioRoomsEarningEnabled) {
    tabs.push({
      key: 'audioRoomsEarnings',
      label: 'Audio rooms',
      data: audioRoomsEarnings?.chart,
      tableData: transformAudioRoomsEarningTableData(audioRoomsEarnings?.earnings),
      tableTitle: 'Audio rooms Earnings',
    });
  }

  if (configuration.videoRoomsEarningEnabled) {
    tabs.push({
      key: 'videoRoomsEarnings',
      label: 'Video rooms',
      data: videoRoomsEarnings?.chart,
      tableData: transformVideoRoomsEarningTableData(videoRoomsEarnings?.earnings),
      tableTitle: 'Video rooms Earnings',
    });
  }

  if (configuration.shopRevenueEnabled) {
    tabs.push({
      key: 'shopSalesEarnings',
      label: 'Shop sales',
      data: shopSalesEarnings?.chart,
      tableData: transformShopSalesEarningsTableData(shopSalesEarnings?.earnings),
      tableTitle: 'Shop sales Earnings',
    });
  }

  if (configuration.raffleDrawEarningEnabled) {
    tabs.push({
      key: 'raffleDrawsEarnings',
      label: 'Raffle draw',
      data: raffleDrawsEarnings?.chart,
      tableData: transformRaffleDrawEarningsTableData(raffleDrawsEarnings?.earnings),
      tableTitle: 'Raffle draw Earnings',
    });
  }

  if (configuration.promoCodeEarningEnabled) {
    tabs.push({
      key: 'promoCodesEarnings',
      label: 'Promo codes',
      data: promoCodesEarnings?.chart,
      tableData: transformPromoCodesEarningsTableData(promoCodesEarnings?.earnings),
      tableTitle: 'Promo codes Earnings',
    });
  }

  return tabs;
};

const transformPromoCodesEarningsTableData = (data?: IPromoCodeEarnings['earnings'] | null) => {
  if (!data) return [];

  return data.map((promoCode) => {
    return {
      date: { field: 'Date', value: Helpers.formatDate(promoCode.date) },
      subscribers: { field: 'Subscribers', value: Helpers.formatNumber(promoCode.subscribers) },
      earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(promoCode.earnings)}` },
    };
  });
};

const transformRaffleDrawEarningsTableData = (data?: IRaffleDrawEarnings['earnings'] | null) => {
  if (!data) return [];

  return data.map((raffleDraw) => {
    return {
      raffleDrawName: { field: 'Raffle Draw Name', value: raffleDraw.name },
      startDate: { field: 'Start date', value: Helpers.formatDate(raffleDraw.startDate) },
      endDate: { field: 'End date', value: Helpers.formatDate(raffleDraw.endDate) },
      entries: { field: 'Entries', value: Helpers.formatNumber(raffleDraw.entries) },
      earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(raffleDraw.earnings)}` },
    };
  });
};

const transformShopSalesEarningsTableData = (data?: IShopSaleEarnings['earnings'] | null) => {
  if (!data) return [];

  return data.map((shopSale) => {
    return {
      productName: { field: 'Product Name', value: shopSale.name },
      date: { field: 'Date', value: Helpers.formatDate(shopSale.date) },
      unitsSold: { field: 'Units sold', value: Helpers.formatNumber(shopSale.unitsSold) },
      earnings: { field: 'Earnings', value: `$${Helpers.formatNumber(shopSale.earnings)}` },
    };
  });
};

const transformAudioRoomsEarningTableData = (data?: IAudioRoomEarnings['earnings'] | null) => {
  if (!data) return [];

  return data.map((audioRoom) => {
    return {
      roomName: { field: 'Room name', value: audioRoom.roomName },
      date: { field: 'Date', value: Helpers.formatDate(audioRoom.date) },
      gifts: { field: 'Gifts', value: Helpers.formatNumber(audioRoom.gifts) },
      points: { field: 'Points', value: Helpers.formatNumber(audioRoom.points) },
      users: { field: 'Users', value: Helpers.formatNumber(audioRoom.users) },
      joinsEarnings: {
        field: 'Joins Earnings',
        value: `$${Helpers.formatNumber(audioRoom.joinsEarnings)}`,
      },
      giftsEarnings: {
        field: 'Gifts earnings',
        value: `$${Helpers.formatNumber(audioRoom.giftsEarnings)}`,
      },
    };
  });
};

const transformVideoRoomsEarningTableData = (data?: IVideoRoomEarnings['earnings'] | null) => {
  if (!data) return [];

  return data?.map((videoRoom) => ({
    roomName: { field: 'Room name', value: videoRoom.roomName },
    date: { field: 'Date', value: Helpers.formatDate(videoRoom.date) },
    gifts: { field: 'Gifts', value: Helpers.formatNumber(videoRoom.gifts) },
    points: { field: 'Points', value: Helpers.formatNumber(videoRoom.points) },
    users: { field: 'Users', value: Helpers.formatNumber(videoRoom.users) },
    joinsEarnings: {
      field: 'Joins Earnings',
      value: `$${Helpers.formatNumber(videoRoom.joinsEarnings)}`,
    },
    giftsEarnings: {
      field: 'Gifts earnings',
      value: `$${Helpers.formatNumber(videoRoom.giftsEarnings)}`,
    },
  })) || [];
};

const transformVideosEarningsTableData = (data?: IVideoEarnings['earnings'] | null) => {


  return (
    data?.map((video) => ({
      img: {
        field: 'Thumbnail',
        value: <img src={video.thumbnail} alt={video.title} />,
      },
      title: { field: 'Video Title', value: video.title },
      views: { field: 'Views', value: Helpers.formatNumber(video.totalViews) },
      likes: { field: 'Likes', value: Helpers.formatNumber(video.likesCount) },
      shares: { field: 'Shares', value: Helpers.formatNumber(video.sharesCount) },
      comments: { field: 'Comments', value: Helpers.formatNumber(video.commentsCount) },
      type: {
        field: 'Type',
        value: (
          <VideoStatusTag isPremium={Boolean(video.isPremium)} previewTime={video.previewTime} />
        ),
      },
      earning: { field: 'Video Earnings', value: `$${Helpers.formatNumber(video.earning)}` },
    })) || []
  );
};
