import React from 'react';

import AvatarIcon from '../../../assets/images/Avatar.png';
import Helpers from '../../../utils/Helpers';
import classNames from 'classnames';

import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './InfluencerStatsWidget.module.scss';

const InfluencerStatsWidget = ({ data, loading = false }) => {
  return (
    <div className={classNames(styles.influencerContainer, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div className={styles.influencerIcon}>
            <img src={data?.avatar || AvatarIcon} alt="avatar" />
          </div>
          <div className={styles.influencerInfo}>
            <h1>{data?.name}</h1>
          </div>
          <div className={styles.description}>
            <div>
              <p>Videos</p>
              <h1>{data?.videosCount || 0}</h1>
            </div>
            <div>
              <p>Total Views</p>
              <h1>{data?.videoViewsCount ? Helpers.formatNumber(data.videoViewsCount) : 0}</h1>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default InfluencerStatsWidget;
