import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { ReactComponent as AnalyticsIcon } from '../../dashboard/assets/icons/AnalyticsIcon.svg';
import { ReactComponent as DashboardIcon } from '../../dashboard/assets/icons/DashboardIcon.svg';
import { ReactComponent as EarningsIcon } from '../../dashboard/assets/icons/EarningsIcon.svg';
import { ReactComponent as LogOutIcon } from '../../dashboard/assets/icons/LogOutIcon.svg';
import { ReactComponent as VideoIcon } from '../../dashboard/assets/icons/VideoIcon.svg';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { logout } from '../../store/auth';
import { ReactComponent as UploadIcon } from '../../upload/assets/icons/upload.svg';
import { ReactComponent as UploadsIcon } from '../../upload/assets/icons/uploads.svg';

import styles from './PrivateLayout.module.scss';

const sideMenu = [
  {
    link: '/dashboard',
    iconComponent: <DashboardIcon />,
    title: 'Dashboard',
    showMobile: false,
  },
  {
    link: '/dashboard/myVideos',
    iconComponent: <VideoIcon />,
    title: 'My Videos',
    showMobile: false,
  },
  {
    link: '/dashboard/analytics',
    iconComponent: <AnalyticsIcon />,
    title: 'Channel Analytics',
    showMobile: false,
  },
  {
    link: '/dashboard/earnings',
    iconComponent: <EarningsIcon />,
    title: 'Earnings',
    showMobile: false,
  },
  {
    link: '/upload',
    iconComponent: <UploadIcon />,
    title: 'Upload Content',
    showMobile: false,
  },
  {
    link: '/uploads',
    iconComponent: <UploadsIcon />,
    title: 'Your Uploads',
    showMobile: true,
  },
];

const PrivateLayout = ({ history, location, children }) => {
  const { isDesktop } = useDeviceDetect();
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState(location.pathname);

  const handleLogout = () => {
    dispatch(logout(history));
  };

  useEffect(() => {
    if (location.pathname.startsWith('/dashboard/myVideos')) {
      setSelectedItem('/dashboard/myVideos');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location]);

  return (
    <main className={styles.layout}>
      {isDesktop && (
        <aside className={styles.sideBarContainer}>
          <div>
            <Link to="/">
              <img src="/assets/images/vuzcreators_logo.png" alt="logo" />
            </Link>
            {sideMenu.map(
              (sideMenuElement) =>
                (sideMenuElement.showMobile || isDesktop) && (
                  <NavLink key={sideMenuElement.link} to={sideMenuElement.link}>
                    <div
                      className={`${styles.menuItem} ${
                        selectedItem === sideMenuElement.link && styles.selectedMenuItem
                      }`}
                    >
                      <div className={styles.menuItemIconContainer}>
                        {sideMenuElement.iconComponent}
                      </div>
                      <p>{sideMenuElement.title}</p>
                    </div>
                  </NavLink>
                ),
            )}
          </div>
          <div className={styles.logOutContainer} onClick={handleLogout}>
            <LogOutIcon />
            <p>Log Out</p>
          </div>
        </aside>
      )}
      <div className={styles.mainSection}>{children}</div>
    </main>
  );
};

export default withRouter(PrivateLayout);
