import React from 'react';

import styles from '../BarChart.module.scss';

export const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const value = payload[0];
    const data = Object.values(value.payload.extended);

    return (
      <div className={styles.customTooltip}>
        <h1>{value.payload.name} revenue</h1>
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Count</th>
              <th>Earning</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((datum, index) => {
              return (
                <tr key={index}>
                  <td>{datum.title} </td>
                  <td>{datum.count}</td>
                  <td>{datum.earning}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};
