import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from '../../../layout/PrivateRoute';
import EnterPin from '../views/Auth/EnterPin/EnterPin';
import ForgotPassword from '../views/Auth/ForgotPassword';
import Login from '../views/Auth/Login';
import Register from '../views/Auth/Register';
import ResetPassword from '../views/Auth/ResetPassword';
import NotFound from '../views/NotFound';
import Upload from '../views/Upload/Upload';
import Uploads from '../views/Uploads/Uploads';

export const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/enter-pin" component={EnterPin} />
        <Route exact path="/reset-password" component={ResetPassword} />

        <Route exact path="/login" component={Login} />

        <PrivateRoute exact path="/upload" component={Upload} />
        <PrivateRoute exact path="/uploads" component={Uploads} />

        <Route component={NotFound} />
      </Switch>
    </>
  );
};
