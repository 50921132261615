import React from 'react';

export const CustomDot = ({
  cx,
  cy,
  fillColor = 'transparent',
  fillColor2 = '#fff',
  strokeColor = '#1B2559',
}) => (
  <g>
    <circle cx={cx} cy={cy} r={8} fill={fillColor} stroke={strokeColor} strokeWidth={5} />
    <circle cx={cx} cy={cy} r={5.5} fill={fillColor2} />
  </g>
);
