import React from 'react';

import { ReactComponent as LoaderIcon } from '../../assets/icons/LoaderIcon.svg';

import styles from './Loader.module.scss';

const Loader = ({ width = 50, height = 50 }) => {
  return (
    <div>
      <LoaderIcon width={width} height={height} className={styles.loader} />
    </div>
  );
};

export default Loader;
