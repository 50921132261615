const Helpers = {
  isAuthenticated: () => Boolean(localStorage.getItem('isAuthenticated')),
  applyQueryParameters(query, params) {
    if (!query || typeof query !== 'string') return;

    let composedQuery = query;
    if (!composedQuery.includes('?')) {
      composedQuery = composedQuery + '?';
    }

    Object.keys(params).forEach((paramKey) => {
      composedQuery = `${composedQuery}${paramKey}=${params[paramKey].toString()}&`;
    });

    return composedQuery;
  },
};

export default Helpers;
