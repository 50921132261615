import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { setAlert } from '../../../../store/alert';
import { register, resetForm } from '../../../../store/auth';
import Helpers from '../../../utils/Helpers';
import BaseAuth from '../../auth/BaseAuth/BaseAuth';
import DynamicScrollToTop from '../../layout/DynamicScrollToTop';
import classNames from 'classnames';

import authStyles from './Auth.module.scss';

const facebookUrlRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/.*$/i;
const instagramUrlRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/.*$/i;
const tikTokUrlRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/.*$/i;

const defaultFormState = {
  name: '',
  email: '',
  instagram: '',
  facebook: '',
  tikTok: '',
};

const Register = () => {
  const dispatch = useDispatch();

  const { btnLoading, formResultRetrieved, isAuthenticated } = useSelector((state) => state.auth);
  /* eslint-disable no-unused-vars */
  const [, setIsFormValid] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [photoFile, setPhotoFile] = useState('');

  const profileImageFileInputRef = useRef(null);

  const profileImagePreview = useMemo(
    () => (photoFile ? URL.createObjectURL(photoFile) : null),
    [photoFile],
  );

  const [errors, setErrors] = useState({
    name: null,
    email: null,
    instagram: null,
    facebook: null,
    tikTok: null,
  });
  const [formData, setFormData] = useState(defaultFormState);

  const { name, email, instagram, facebook, tikTok } = formData;

  const onChange = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (inputName === 'email') {
      if (!inputValue) {
        setErrors({ ...errors, email: 'Email is required' });
        setIsFormValid(false);
        return;
      }
      if (!Helpers.isEmail(inputValue)) {
        setErrors({ ...errors, email: 'Invalid email' });
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setErrors({ ...errors, email: null });
    }

    if (inputName === 'name') {
      if (!inputValue) {
        setErrors({ ...errors, name: 'Name is required' });
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setErrors({ ...errors, name: null });
    }

    if (inputName === 'facebook') {
      if (!inputValue) {
        setErrors({ ...errors, facebook: null });
        return;
      }
      if (inputValue && !facebookUrlRegex.test(inputValue)) {
        setErrors({ ...errors, facebook: 'Invalid profile url' });
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setErrors({ ...errors, facebook: null });
    }

    if (inputName === 'instagram') {
      if (!inputValue) {
        setErrors({ ...errors, instagram: null });
        return;
      }
      if (inputValue && !instagramUrlRegex.test(inputValue)) {
        setErrors({ ...errors, instagram: 'Invalid profile url' });
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setErrors({ ...errors, instagram: null });
    }

    if (inputName === 'tikTok') {
      if (!inputValue) {
        setErrors({ ...errors, tikTok: null });
        return;
      }
      if (inputValue && !tikTokUrlRegex.test(inputValue)) {
        setErrors({ ...errors, tikTok: 'Invalid profile url' });
        setIsFormValid(false);
        return;
      }

      setIsFormValid(true);
      setErrors({ ...errors, tikTok: null });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let hasError = false;
    if (!name) {
      setErrors((err) => ({ ...err, name: 'Name is required' }));
      hasError = true;
    }
    if (!email) {
      setErrors((err) => ({ ...err, email: 'Email is required' }));
      hasError = true;
    }
    if (instagram && !instagramUrlRegex.test(instagram)) {
      setErrors((err) => ({ ...err, instagram: 'Invalid profile url' }));
      hasError = true;
    }
    if (facebook && !facebookUrlRegex.test(facebook)) {
      setErrors((err) => ({ ...err, facebook: 'Invalid profile url' }));
      hasError = true;
    }
    if (tikTok && !tikTokUrlRegex.test(tikTok)) {
      setErrors((err) => ({ ...err, tikTok: 'Invalid profile url' }));
      hasError = true;
    }

    if (hasError) return;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    if (photoFile) {
      formData.append('image', photoFile);
    }
    if (instagram) {
      formData.append('instagram_url', instagram);
    }
    if (facebook) {
      formData.append('facebook_url', facebook);
    }
    if (tikTok) {
      formData.append('tiktok_url', tikTok);
    }
    dispatch(register(formData));
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const onOpenProfileImageFileDialog = (event) => {
    event.preventDefault();

    profileImageFileInputRef.current.click();
  };

  const onChangePhoto = (e) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const fileName = e.target.files[0].name;
      const fileExtension = fileName.split('.')[1];
      if (
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'png' ||
        fileExtension === 'bmp'
      ) {
        setPhotoFile(e.target.files[0]);
      } else {
        dispatch(setAlert('Please choose a valid image file (jpg, jpeg, png)', 'danger'));
      }
    }
  };

  useEffect(() => {
    if (formResultRetrieved) {
      setIsModalVisible(true);
      setFormData(defaultFormState);
      setPhotoFile('');
      dispatch(resetForm());
    }
  }, [formResultRetrieved, dispatch]);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <BaseAuth>
      <DynamicScrollToTop />

      <form className={authStyles.authForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classNames(authStyles.authCard, 'mt-3')}>
          <h2>Register</h2>
          <br />

          <div className={classNames(authStyles.profilePhoto, 'form-group')}>
            <img src={profileImagePreview || '/assets/icons/profile.svg'} alt="Profile" />
            <div className={authStyles.uploadControl}>
              <span>Profile Photo</span>
              <button
                className={classNames(authStyles.uploadPhotoBtn)}
                type="button"
                onClick={onOpenProfileImageFileDialog}
              >
                Choose file
              </button>
            </div>
            <input
              ref={profileImageFileInputRef}
              type="file"
              className={authStyles.customFileInput}
              id="customFile"
              onChange={onChangePhoto}
              accept=".jpg,.jpeg,.png"
            />
          </div>

          <div className="form-group">
            <label className={authStyles.authLabel}>Name</label>
            <input
              className={classNames(authStyles.authInput, errors.name && authStyles.inputError)}
              placeholder="Type your name here"
              name="name"
              value={name}
              onChange={onChange}
              autoComplete="off"
            />
            {errors.name && <label className={authStyles.labelError}>{errors.name}</label>}
          </div>

          <div className="form-group">
            <label className={authStyles.authLabel}>Email Address</label>
            <input
              className={classNames(authStyles.authInput, errors.email && authStyles.inputError)}
              placeholder="Type your email here"
              name="email"
              value={email}
              onChange={onChange}
              autoComplete="off"
            />
            {errors.email && <label className={authStyles.labelError}>{errors.email}</label>}
          </div>

          <div className="form-group">
            <label className={authStyles.authLabel}>Your Social Links</label>
            <div className={authStyles.socialInput}>
              <img
                className={authStyles.socialInputIcon}
                src="/assets/icons/instagram.svg"
                alt="Instagram link"
              />
              <input
                className={classNames(errors.instagram && authStyles.inputError)}
                placeholder="Type your Instagram link here"
                name="instagram"
                value={instagram}
                onChange={onChange}
                autoComplete="off"
              />
              {formData.instagram && !errors.instagram && (
                <img
                  className={authStyles.validInput}
                  src="/assets/icons/success.svg"
                  alt="Valid Instagram link"
                />
              )}
            </div>
            {errors.instagram && (
              <label className={authStyles.labelError}>{errors.instagram}</label>
            )}
          </div>

          <div className="form-group">
            <div className={authStyles.socialInput}>
              <img
                className={authStyles.socialInputIcon}
                src="/assets/icons/facebook.svg"
                alt="Facebook link"
              />
              <input
                className={classNames(errors.facebook && authStyles.inputError)}
                placeholder="Type your Facebook link here"
                name="facebook"
                value={facebook}
                onChange={onChange}
                autoComplete="off"
              />
              {formData.facebook && !errors.facebook && (
                <img
                  className={authStyles.validInput}
                  src="/assets/icons/success.svg"
                  alt="Valid Facebook link"
                />
              )}
            </div>
            {errors.facebook && <label className={authStyles.labelError}>{errors.facebook}</label>}
          </div>

          <div className="form-group">
            <div className={authStyles.socialInput}>
              <img
                className={authStyles.socialInputIcon}
                src="/assets/icons/tikTok.svg"
                alt="Tiktok link"
              />
              <input
                className={classNames(errors.tikTok && authStyles.inputError)}
                placeholder="Type your Tiktok link here"
                name="tikTok"
                value={tikTok}
                onChange={onChange}
                autoComplete="off"
              />
              {formData.tikTok && !errors.tikTok && (
                <img
                  className={authStyles.validInput}
                  src="/assets/icons/success.svg"
                  alt="Valid Tiktok link"
                />
              )}
            </div>
            {errors.tikTok && <label className={authStyles.labelError}>{errors.tikTok}</label>}
          </div>

          <button
            className={classNames(authStyles.authPrimaryBtn, 'btn-block', 'mt-3')}
            disabled={btnLoading}
          >
            {btnLoading && <i className="fas fa-circle-notch fa-spin"></i>}
            Register
          </button>

          <p className={authStyles.authSecondaryBtn}>
            <Link to="/login" className={authStyles.blackLink}>
              Already have an account?
            </Link>
          </p>
        </div>

        <Modal
          show={isModalVisible}
          onHide={handleCloseModal}
          centered
          size="md"
          className={authStyles.registerSuccessModal}
        >
          <Modal.Header className={authStyles.modalHeader} closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={authStyles.modalTitle}>
              Thank you! <br /> We will get back to you soon!
            </p>
            <p className={classNames(authStyles.modalText, 'mt-4')}>
              We will send you an email confirmation. <br />
              Please don’t forget to check your email inbox.
            </p>
          </Modal.Body>
        </Modal>
      </form>
    </BaseAuth>
  );
};

export default Register;
