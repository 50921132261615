import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowBackIcon } from '../../../../assets/icons/ArrowBackIcon.svg';
import CustomDoughnutChart from '../../../../components/charts/DoughnutChart/DoughnutChart';
import CustomPieChart from '../../../../components/charts/PieChart/PieChart';
import PeriodSelect from '../../../../components/periodSelect/PeriodSelect.tsx';
import InformationWidget from '../../../../components/widgets/informationWidget/InformationWidget';
import TabsWidget from '../../../../components/widgets/tabsWidget/TabsWidget';
import {
  geographyColors,
  subscriptionStatusColors,
  useSingleVideoData,
} from './use-single-video-data';

import styles from './SingleVideo.module.scss';

const tabNames = ['Overview', 'Engagement'];

const SingleVideo = () => {
  const {
    activeTab,
    setActiveTab,
    setDatePeriod,
    videoInfo,
    isUsersInfoLoading,
    isOverviewLoading,
    isEngagementLoading,
    subscriptionStatus,
    geography,
    overview,
    engagement,
  } = useSingleVideoData();

  return (
    <div className={styles.container}>
      <Link to="/dashboard/myVideos">
        <ArrowBackIcon />
      </Link>
      <div className={styles.videoInfo}>
        <div className={styles.videoContainer}>
          <img src={videoInfo?.thumbnail} />
        </div>
        <div>
          <h1>{videoInfo?.title}</h1>
        </div>
      </div>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          {tabNames.map((tabName, index) => (
            <div key={tabName}>
              <button
                className={activeTab === index ? styles.activeTab : ''}
                onClick={() => setActiveTab(index)}
              >
                {tabName}
              </button>
              {activeTab === index && <div className={styles.activeBorder}></div>}
            </div>
          ))}
        </div>
        {activeTab !== 1 && <PeriodSelect value={3} onValueChange={setDatePeriod} />}
      </div>
      {activeTab === 0 && <TabsWidget data={overview} loading={isOverviewLoading} />}
      {activeTab === 1 && <TabsWidget data={engagement} loading={isEngagementLoading} />}
      <div className={styles.widgets}>
        <InformationWidget
          data={geography}
          loading={isUsersInfoLoading}
          chart={
            <CustomDoughnutChart
              data={geography}
              height={122}
              width={122}
              innerRadius={34}
              outerRadius={54}
              colors={geographyColors}
            />
          }
          name="Geography"
          description="The type of users watching your videos"
        />
        <InformationWidget
          data={subscriptionStatus}
          loading={isUsersInfoLoading}
          chart={
            <CustomPieChart
              data={subscriptionStatus}
              height={106}
              width={106}
              outerRadius={50}
              colors={subscriptionStatusColors}
            />
          }
          name="Subscription status"
          description="The type of subscribers watching your videos"
        />
      </div>
    </div>
  );
};

export default SingleVideo;
