import React from 'react';

import { generateRandomColor } from './colorUtils';
import { Cell, Pie, PieChart } from 'recharts';

const CustomPieChart = ({
  data,
  width,
  height,
  yDataKey = 'value',
  cx = '50%',
  cy = '50%',
  outerRadius = 80,
  stroke = 'none',
  colors = Array.from({ length: data.length }, () => generateRandomColor()),
}) => {
  return (
    <PieChart width={width} height={height}>
      <Pie data={data} cx={cx} cy={cy} outerRadius={outerRadius} dataKey={yDataKey} stroke={stroke}>
        {data?.map((_, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export default CustomPieChart;
