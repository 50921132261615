import React from 'react';

import { ReactComponent as StatisticIcon } from '../../../assets/icons/StatisticsIcon.svg';
import classNames from 'classnames';

import commonStyles from '../../../assets/styles/Common.module.scss';
import styles from './SingleStatsWidget.module.scss';

interface ISingleStatsWidgetProps {
  icon?: React.ReactNode;
  name: string;
  value?: string | number;
  statistic?: string;
  iconBackground?: string;
  loading?: boolean;
}

const SingleStatsWidget: React.FC<ISingleStatsWidgetProps> = ({
  icon,
  name,
  value = 0,
  statistic,
  iconBackground = '#074DFF',
  loading = false,
}) => {
  return (
    <div className={classNames(styles.singleStatsWidgetContainer, loading && commonStyles.loading)}>
      {!loading ? (
        <>
          <div style={{ background: iconBackground }} className={styles.iconContainer}>
            {icon}
          </div>
          <div className={styles.statsDescription}>
            <p>{name}</p>
            <h1>{value}</h1>
          </div>
          {statistic && (
            <div
              className={`${styles.statsContainer} ${
                parseFloat(statistic) < 0 && styles.negativeStatistic
              }`}
            >
              <p>{statistic}</p>
              <StatisticIcon />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SingleStatsWidget;
