import Big from 'big.js';

const monthsMap = new Map([
  ['01', 'Jan'],
  ['02', 'Feb'],
  ['03', 'Mar'],
  ['04', 'Apr'],
  ['05', 'May'],
  ['06', 'Jun'],
  ['07', 'Jul'],
  ['08', 'Aug'],
  ['09', 'Sep'],
  ['10', 'Oct'],
  ['11', 'Nov'],
  ['12', 'Dec'],
]);

const Helpers = {
  formatNumber(value?: number | null) {
    if (value === null || value === undefined || isNaN(value)) {
      return 'Invalid input';
    }

    if (value < 1000) {
      return value % 1 === 0 ? value : value.toFixed(2); // Less than 1000, no postfix, one decimal point
    }

    if (value < 1000000) {
      return (value / 1000).toFixed(1) + 'K'; // Thousands, one decimal point, with 'K' postfix
    }

    return (value / 1000000).toFixed(1) + 'M'; // Millions, one decimal point, with 'M' postfix
  },

  formatDate(inputDate: string | Date) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  },

  formatToolTipDate(inputDate: string | Date) {
    const date = new Date(inputDate);
    const day = date.toLocaleString('en-us', { weekday: 'short' });
    const dateNum = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month}, ${year}`;
  },

  /**
   * Converts total seconds to HH:MM:SS format.
   * @param {number} totalSeconds - The total seconds to convert.
   * @returns {string} The formatted time string in HH:MM:SS.
   */
  formatSeconds(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    // Format seconds with leading zeros
    const formattedSeconds = Math.round(seconds).toString().padStart(2, '0');

    return `${hours}:${minutes.toString().padStart(2, '0')}:${formattedSeconds}`;
  },

  formatShortDate(dateString: string) {
    const [day, month, year] = dateString.split('-');

    return new Date(`${month}-${day}-${year}`).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric',
    });
  },

  getMonthFromDate(inputDate: string) {
    const [day, month, year] = inputDate.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    return date.toLocaleDateString('en-US', { month: 'short' });
  },

  getMonthAndYearFromDate(inputDate: string, isDateReversed = false) {
    const splittedDate = inputDate.split('-').map(Number) as [number, number, number];

    const day = isDateReversed ? splittedDate[2] : splittedDate[0];
    const month = splittedDate[1];
    const year = isDateReversed ? splittedDate[0] : splittedDate[2];

    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const monthName = date.toLocaleDateString('en-US', { month: 'short' });

    return `${monthName} ${year}`;
  },

  getMonthFromISODate(inputDate: string) {
    const [year, month, day] = inputDate.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    return date.toLocaleDateString('en-US', {
      month: 'short',
    });
  },

  calculatePercentage(value: number, total: number) {
    return total ? Math.round((value / total) * 100) : 0;
  },

  calculateSum<T>(data: T[], key = 'earning') {
    let sum = Big(0);

    data.forEach((entry) => {
      if (Array.isArray(key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        key.forEach((iterateKey) => (sum = sum.plus((entry as any)[iterateKey])));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sum = sum.plus((entry as any)[key]);
      }
    });

    return sum.toNumber();
  },

  isoMonthNumberToShortString: (key: string) => monthsMap.get(key),

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepCopy(obj: any | null) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copy: any = Array.isArray(obj) ? [] : {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        copy[key] = this.deepCopy(obj[key]);
      }
    }

    return copy;
  },

  getShortedMonthNames() {
    return [...monthsMap.values()];
  },

  groupByDate<T>({
    data,
    dateKey = 'date',
    groupBy = 'date',
  }: {
    data?: T[];
    dateKey?: string;
    groupBy?: string;
  }) {
    return data?.reduce((prev: Record<string, T[]>, curr) => {
      const dateValue = (curr as Record<string, string>)[dateKey];

      let date: string = '';

      if (groupBy === 'year-month') {
        const splittedDate = dateValue.split('-');
        date = `${splittedDate[0]}-${splittedDate[1]}`;
      } else if (groupBy === 'month') {
        date = dateValue.split('-')[1];
      } else {
        date = dateValue.split('T')[0];
      }

      // date = groupBy === 'month' ? dateValue.split('-')[1] : dateValue.split('T')[0];

      prev[date] ? prev[date].push(curr) : (prev[date] = [curr]);

      return prev;
    }, {});
  },
};

export default Helpers;
