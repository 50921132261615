const get = (key) => {
  const cachedData = localStorage.getItem(key);

  if (!cachedData) {
    return null;
  }

  try {
    const data = JSON.parse(cachedData);

    if (data.expire && data.expire <= Date.now()) {
      localStorage.removeItem(key);

      return null;
    }

    return data.value;
  } catch (error) {
    return null;
  }
};

const set = (key, value, ttlInSeconds) => {
  if (!value) {
    return;
  }

  const data = {
    value,
  };

  if (ttlInSeconds) {
    data.expire = Date.now() + ttlInSeconds * 1000;
  }

  localStorage.setItem(key, JSON.stringify(data));
};

const clear = (key) => localStorage.removeItem(key);

export const cacheService = {
  get,
  set,
  clear,
};
