import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { saveNewPassword } from '../../../../store/auth';
import BaseAuth from '../../auth/BaseAuth/BaseAuth';
import Alert from '../../layout/Alert';
import DynamicScrollToTop from '../../layout/DynamicScrollToTop';
import classNames from 'classnames';

import authStyles from './Auth.module.scss';

const ResetPassword = ({ history }) => {
  const dispatch = useDispatch();
  const { btnLoading, isAuthenticated, passwordResetToken } = useSelector((state) => state.auth);
  const [isFormValid, setIsFormValid] = useState(false);

  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
  });
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const { password, confirmPassword } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (name === 'password') {
      if (value.length < 8) {
        setErrors({
          ...errors,
          password: 'Password must be at least 8 characters',
        });
        setIsFormValid(false);
        return;
      }

      setErrors({ ...errors, password: null });
    }

    if (name === 'confirmPassword') {
      if (value !== password) {
        setErrors({ ...errors, confirmPassword: 'Passwords must match' });
        setIsFormValid(false);
        return;
      }

      setErrors({ ...errors, confirmPassword: null });
    }

    setIsFormValid(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      setErrors((err) => ({ ...err, password: 'Password is required' }));
    }
    if (!confirmPassword) {
      setErrors((err) => ({
        ...err,
        confirmPassword: 'Confirm Password is required',
      }));
    }

    if (!isFormValid) return;

    dispatch(saveNewPassword(password, confirmPassword, passwordResetToken, history));
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <BaseAuth>
      <DynamicScrollToTop />
      <form className={authStyles.authForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classNames(authStyles.authCard, 'mt-3')}>
          <h2>Reset Password</h2>
          <br />

          <Alert />
          <div className="form-group">
            <label className={authStyles.authLabel}>New Password</label>
            <input
              className={classNames(authStyles.authInput, errors.password && authStyles.inputError)}
              placeholder="Enter your new password here"
              name="password"
              value={password}
              type="password"
              onChange={(e) => onChange(e)}
            />
            {errors.password && <label className={authStyles.labelError}>{errors.password}</label>}
          </div>

          <div className="form-group">
            <label className={authStyles.authLabel}>Confirm New Password</label>
            <input
              className={classNames(
                authStyles.authInput,
                errors.confirmPassword && authStyles.inputError,
              )}
              placeholder="Confirm your new password"
              name="confirmPassword"
              value={confirmPassword}
              type="password"
              onChange={(e) => onChange(e)}
            />
            {errors.confirmPassword && (
              <label className={authStyles.labelError}>Passwords Must Match</label>
            )}
          </div>

          <button
            className={classNames(authStyles.authPrimaryBtn, 'btn-block', 'mt-3')}
            disabled={btnLoading}
          >
            {btnLoading && <i className="fas fa-circle-notch fa-spin"></i>}
            Save New Password
          </button>

          <p className={authStyles.authSecondaryBtn}>
            <Link to="/login" className={authStyles.blackLink}>
              Back to login
            </Link>
          </p>
        </div>
      </form>
    </BaseAuth>
  );
};

export default withRouter(ResetPassword);
