import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import { setAlert } from '../../../../../store/alert';
import { verifyPin } from '../../../../../store/auth';
import BaseAuth from '../../../auth/BaseAuth/BaseAuth';
import Alert from '../../../layout/Alert';
import DynamicScrollToTop from '../../../layout/DynamicScrollToTop';
import classNames from 'classnames';

import authStyles from '../Auth.module.scss';
import styles from './EnterPin.module.scss';

const EnterPin = ({ history }) => {
  const dispatch = useDispatch();
  const { btnLoading, isAuthenticated } = useSelector((state) => state.auth);
  const [isFormValid, setIsFormValid] = useState(false);

  const [errors, setErrors] = useState({
    pin: null,
  });
  const [formData, setFormData] = useState({
    pin: '',
  });

  const { pin } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;
    let checkFormValidity = true;
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (name === 'pin' && value === '') {
      setErrors({ ...errors, pin: 'Invalid pin' });
      checkFormValidity = false;
    } else {
      setErrors({ ...errors, pin: null });
    }

    setIsFormValid(checkFormValidity);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) {
      dispatch(setAlert('Please enter a valid pin', 'danger'));
    } else {
      // console.log(formData);
      dispatch(verifyPin(pin, history));
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <BaseAuth>
      <DynamicScrollToTop />

      <form className={authStyles.authForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classNames('mt-3', authStyles.authCard)}>
          <h2>Verify</h2>
          <h3 className={styles.enterPinSecondaryText}>
            Enter the PIN code sent to your email address
          </h3>
          <br />

          <Alert />
          <div className="form-group">
            <label className={authStyles.authLabel}>Confirmation Code</label>
            <input
              className={classNames(authStyles.authInput, errors.pin && authStyles.inputError)}
              placeholder="Type the code here"
              name="pin"
              value={pin}
              onChange={(e) => onChange(e)}
            />
            {errors.pin && <label className={authStyles.labelError}>Invalid PIN</label>}
          </div>

          <button
            className={classNames(authStyles.authPrimaryBtn, 'btn', 'btn-block', 'mt-3')}
            disabled={btnLoading}
          >
            {btnLoading && <i className="fas fa-circle-notch fa-spin"></i>}
            Submit
          </button>
        </div>
      </form>
    </BaseAuth>
  );
};

export default withRouter(EnterPin);
