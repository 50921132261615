import React from 'react';

const NotFound = () => {
  return (
    <section className="not-found-section">
      <p>NotFound</p>
    </section>
  );
};

export default NotFound;
