import React, { useRef, useState } from 'react';

import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CustomLineChart = ({
  data,
  width = undefined,
  xDataValue = null,
  height = 400,
  xDataKey = 'name',
  yDataKey = 'value',
  yDataKey2 = null,
  xThickColor = '#A3AED0',
  yThickColor = '#666',
  strokeColor = '#1B2559',
  strokeColor2 = '#A3AED0',
  CustomDot = () => {},
  CustomToolTip = () => {},
  toolTipEndCharacter = '',
  xCustomTickFormatter = (value) => value,
  yCustomTickFormatter = (value) => value,
}) => {
  const lineRef = useRef(null);
  const [point, setPoint] = useState(null);

  const onChartMouseMove = (chart) => {
    if (chart.isTooltipActive) {
      const currentPoint = lineRef.current.props.points[chart.activeTooltipIndex];
      if (currentPoint !== point) {
        setPoint(currentPoint);
      }
    }
  };

  const onChartMouseLeave = () => {
    setPoint(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 20,
          }}
          onMouseMove={onChartMouseMove}
          onMouseLeave={onChartMouseLeave}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey={xDataKey}
            tickFormatter={xCustomTickFormatter}
            tickLine={false}
            dy={20}
            tick={{
              fill: xThickColor,
              fontSize: 12,
              fontFamily: 'Inter, sans-serif',
            }}
          />
          <YAxis
            domain={[() => 0, (dataMax) => Math.ceil(dataMax * 1.2)]}
            tickFormatter={yCustomTickFormatter}
            tickLine={false}
            axisLine={false}
            dx={0}
            tick={{
              fill: yThickColor,
              fontSize: 12,
              fontFamily: 'Inter, sans-serif',
            }}
          />
          <Tooltip content={() => null} cursor={false} />
          <Legend />
          <Line
            cursor="pointer"
            type="monotone"
            dataKey={yDataKey}
            strokeWidth={4}
            stroke={strokeColor}
            dot={false}
            activeDot={<CustomDot />}
            name=""
            legendType="none"
            ref={lineRef}
          />
          {yDataKey2 && (
            <Line
              cursor="pointer"
              type="monotone"
              dataKey={yDataKey2}
              strokeWidth={4}
              stroke={strokeColor2}
              dot={false}
              activeDot={<CustomDot />}
              name=""
              legendType="none"
              ref={lineRef}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      <CustomToolTip
        yDataKey={yDataKey}
        xDataKey={xDataKey}
        yDataKey2={yDataKey2}
        point={point}
        height={height}
        xDataValue={xDataValue}
        toolTipEndCharacter={toolTipEndCharacter}
      />
    </div>
  );
};

export default CustomLineChart;
