import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './i18n';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.Fragment>
      <Suspense fallback="">
        <App />
      </Suspense>
    </React.Fragment>,
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
