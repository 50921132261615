import React from 'react';

import CustomDoughnutChart from '../../components/charts/DoughnutChart/DoughnutChart';
import CustomPieChart from '../../components/charts/PieChart/PieChart';
import PeriodSelect from '../../components/periodSelect/PeriodSelect';
import ClicksAndUsersChartWidget from '../../components/widgets/clicksAndUsersChartWidget/ClicksAndUsersChartWidget';
import DataTrendsWidget from '../../components/widgets/dataTrendsWidget/DataTrendsWidget';
import InformationWidget from '../../components/widgets/informationWidget/InformationWidget';
import TotalViewsChartWidget from '../../components/widgets/totalViewsChartWidget/TotalViewsChartWidget';
import UserTypeWidget from '../../components/widgets/userTypeWidget/UserTypeWidget';
import { geographyColors, useAnalyticsData, videosTypeColors } from './use-analytics-data';

import styles from './Analytics.module.scss';

const Analytics: React.FC = () => {
  const {
    setDatePeriod,
    isTotalViewsLoading,
    isUsersInfoLoading,
    isVideosTypesLoading,
    isClicksAndUsersLoading,
    geography,
    videosTypes,
    totalViews,
    usersTypes,
    clicksAndUsers,
    clicksAndUsersTrends,
  } = useAnalyticsData();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerTitle}>Channel Analytics</span>
        <PeriodSelect value={3} onValueChange={setDatePeriod} />
      </div>
      <div>
        <TotalViewsChartWidget data={totalViews} loading={isTotalViewsLoading} />
      </div>
      <div>
        <UserTypeWidget data={usersTypes} Chart={CustomPieChart} loading={isUsersInfoLoading} />
      </div>
      <div>
        <ClicksAndUsersChartWidget data={clicksAndUsers} loading={isClicksAndUsersLoading} />
      </div>
      <div>
        <DataTrendsWidget data={clicksAndUsersTrends} loading={isClicksAndUsersLoading} />
      </div>
      <div>
        <InformationWidget
          data={videosTypes}
          chart={
            <CustomPieChart
              data={videosTypes}
              height={106}
              width={106}
              outerRadius={50}
              colors={videosTypeColors}
            />
          }
          name="Videos Type"
          description="The type of videos in your channel"
          loading={isVideosTypesLoading}
        />
      </div>
      <div>
        <InformationWidget
          data={geography}
          chart={
            <CustomDoughnutChart
              data={geography}
              height={122}
              width={122}
              innerRadius={34}
              outerRadius={54}
              colors={geographyColors}
            />
          }
          name="Geography"
          description="The countries users are watching from"
          loading={isUsersInfoLoading}
        />
      </div>
    </div>
  );
};

export default Analytics;
