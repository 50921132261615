import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import styles from './ProgressAlert.module.scss';

const iconsMap = {
  danger: '/assets/icons/danger.svg',
  alert: '/assets/icons/alert.svg',
  success: '/assets/icons/success.svg',
  info: '/assets/icons/info.svg',
};

const colorsClassMap = {
  danger: 'red',
  alert: 'yellow',
  success: 'green',
  info: 'blue',
};

const ProgressAlert = ({ forceWidth }) => {
  const progressAlert = useSelector((state) => state.progressAlert);

  const alertIcon = useCallback((type) => {
    if (!type) return;
    return iconsMap[type];
  }, []);

  const alertColorClass = useCallback((type) => {
    if (!type) return;
    return colorsClassMap[type];
  }, []);

  return (
    <>
      {progressAlert && progressAlert.type && (
        <section
          className={classNames(
            'progress-alert',
            styles.progressAlertSection,
            styles[alertColorClass(progressAlert.type)],
          )}
          style={{ width: forceWidth || 'initial' }}
        >
          <img src={alertIcon(progressAlert.type)} alt="Alert" />
          <div className={styles.alertInfo}>
            <h4 className={styles.alertTitle}>{progressAlert.title}</h4>
            <p className={styles.alertText}>{progressAlert.text}</p>
          </div>
        </section>
      )}
    </>
  );
};

export default ProgressAlert;
