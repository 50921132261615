import React from 'react';

export const CustomLabel = ({ viewBox, max, fill }) => {
  const { x, y, width, height } = viewBox.viewBox;
  const centerX = x + width;
  const centerY = y + height;

  return (
    <g>
      <rect x={centerX - x} y={centerY - 10} width={50} height={20} fill="white" />
      <text
        x={centerX}
        y={centerY}
        dy={3}
        textAnchor="middle"
        fill={fill || '#074DFF'}
        fontSize={12}
        fontFamily="Inter, sans-serif"
      >
        ${max}
      </text>
    </g>
  );
};
