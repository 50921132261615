import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { login } from '../../../../store/auth';
import Helpers from '../../../utils/Helpers';
import BaseAuth from '../../auth/BaseAuth/BaseAuth';
import Alert from '../../layout/Alert';
import DynamicScrollToTop from '../../layout/DynamicScrollToTop';
import classNames from 'classnames';

import authStyles from './Auth.module.scss';

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const { btnLoading, isAuthenticated } = useSelector((state) => state.auth);
  const [isFormValid, setIsFormValid] = useState(false);

  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (name === 'email') {
      if (!value) {
        setErrors({ ...errors, email: 'Email is required' });
        setIsFormValid(false);
        return;
      }
      if (!Helpers.isEmail(value)) {
        setErrors({ ...errors, email: 'Invalid email' });
        setIsFormValid(false);
        return;
      }

      setErrors({ ...errors, email: null });
    }

    if (name === 'password') {
      if (!value) {
        setErrors({ ...errors, password: 'Password is required' });
        setIsFormValid(false);
        return;
      }
      if (value.length < 8) {
        setErrors({
          ...errors,
          password: 'The password must be at least 8 characters',
        });
        setIsFormValid(false);
        return;
      }

      setErrors({ ...errors, password: null });
    }

    setIsFormValid(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setErrors((err) => ({ ...err, email: 'Email is required' }));
    }
    if (!password) {
      setErrors((err) => ({ ...err, password: 'Password is required' }));
    }

    if (!isFormValid) return;

    dispatch(login(formData, history));
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <BaseAuth>
      <DynamicScrollToTop />
      <h3>Welcome VUZ Creator!</h3>
      <form className={authStyles.authForm} onSubmit={(e) => onSubmit(e)}>
        <div className={classNames(authStyles.authCard, 'mt-3')}>
          <h2 className="text-center">Login</h2>
          <br />
          <Alert />

          <div className="form-group">
            <label className={authStyles.authLabel}>Email Address</label>
            <input
              className={classNames(authStyles.authInput, errors.email && authStyles.inputError)}
              placeholder="Type your email here"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              autoComplete="on"
            />
            {errors.email && <label className={authStyles.labelError}>{errors.email}</label>}
          </div>

          <div className="form-group">
            <label className={authStyles.authLabel}>Password</label>
            <input
              className={classNames(authStyles.authInput, errors.password && authStyles.inputError)}
              type="password"
              placeholder="Type your password here"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              autoComplete="on"
            />
            {errors.password && <label className={authStyles.labelError}>{errors.password}</label>}
          </div>

          <button
            className={classNames(authStyles.authPrimaryBtn, 'btn-block', 'mt-3')}
            disabled={btnLoading}
          >
            {btnLoading && <i className="fas fa-circle-notch fa-spin"></i>}
            Login
          </button>

          <p className={authStyles.authSecondaryBtn}>
            <Link to="/forgot-password" className={authStyles.blackLink}>
              Forgot password?
            </Link>
          </p>
        </div>
      </form>
    </BaseAuth>
  );
};

export default withRouter(Login);
